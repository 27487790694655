export interface LandingPage {
  landingPageTitle: string;
  landingPageContent: string;
  roleToAssignToLandingPageUser: string;
}

// Must match Enum in prisma schema
enum AccountType {
  SINGLE_USE = 'SINGLE_USE',
  SINGLE_USE_INVITE = 'SINGLE_USE_INVITE',
  NORMAL = 'NORMAL',
}

// Must match Enum in prisma schema
enum SystemSelfSignUp {
  CONTACT_REQUIRED = 'CONTACT_REQUIRED',
  CONTACT_OPTIONAL = 'CONTACT_OPTIONAL',
  NONE = 'NONE',
}

interface CTA {
  landingPageAction: LandingPageAction;
  label: string;
}

export function callToActionButtons(systemSelfSignUp: SystemSelfSignUp, defaultAccountType: AccountType): CTA[] {
  const ctas: CTA[] = [];
  switch (defaultAccountType) {
    case AccountType.NORMAL:
      switch (systemSelfSignUp) {
        case SystemSelfSignUp.CONTACT_REQUIRED:
          ctas.push({
            label: 'Continue and Sign Up',
            landingPageAction: LandingPageAction.SIGNUP_NORMAL,
          });
          break;
        case SystemSelfSignUp.CONTACT_OPTIONAL:
          ctas.push({
            label: 'Continue and Sign Up',
            landingPageAction: LandingPageAction.SIGNUP_NORMAL,
          });
          ctas.push({
            label: 'Continue Without an Account',
            landingPageAction: LandingPageAction.SIGNUP_SINGLE_USE_INVITE,
          });
          break;
        case SystemSelfSignUp.NONE:
          ctas.push({
            label: 'Error - Not expecting AccountType.NORMAL with SystemSelfSignUp.NONE - Logout',
            landingPageAction: LandingPageAction.LOGOUT,
          });
          break;
        default:
          throw new Error(`Unexpected systemSelfSignUp value: ${systemSelfSignUp} for AccountType.NORMAL`);
      }
      break;
    case AccountType.SINGLE_USE_INVITE:
      switch (systemSelfSignUp) {
        case SystemSelfSignUp.NONE:
          ctas.push({
            label: 'Continue Without an Account',
            landingPageAction: LandingPageAction.SIGNUP_NONE_INVITE,
          });
          break;
        case SystemSelfSignUp.CONTACT_OPTIONAL:
          ctas.push({
            label: 'Continue With an Account',
            landingPageAction: LandingPageAction.SIGNUP_SINGLE_USE_INVITE,
          });
          ctas.push({
            label: 'Continue Without an Account',
            landingPageAction: LandingPageAction.SIGNUP_NONE_INVITE,
          });
          break;
        case SystemSelfSignUp.CONTACT_REQUIRED:
          ctas.push({
            label: 'Error - Not expecting AccountType.SINGLE_USE_INVITE with SystemSelfSignUp.CONTACT_REQUIRED - Logout',
            landingPageAction: LandingPageAction.LOGOUT,
          });
          break;
        default:
          throw new Error(`Unexpected systemSelfSignUp value: ${systemSelfSignUp} for AccountType.SINGLE_USE_INVITE`);
      }
      break;
    case AccountType.SINGLE_USE:
      switch (systemSelfSignUp) {
        case SystemSelfSignUp.NONE:
          ctas.push({
            label: 'Continue Without an Account',
            landingPageAction: LandingPageAction.SIGNUP_NONE,
          });
          break;
        case SystemSelfSignUp.CONTACT_OPTIONAL:
          ctas.push({
            label: 'Continue Without an Account',
            landingPageAction: LandingPageAction.SIGNUP_SINGLE_USE,
          });
          break;
        case SystemSelfSignUp.CONTACT_REQUIRED:
          ctas.push({
            label: 'Error - Not expecting AccountType.SINGLE_USE with SystemSelfSignUp.CONTACT_REQUIRED - Logout',
            landingPageAction: LandingPageAction.LOGOUT,
          });
          break;
        default:
          throw new Error(`Unexpected systemSelfSignUp value: ${systemSelfSignUp} for AccountType.SINGLE_USE`);
      }
      break;
    default:
      throw new Error(`Unexpected defaultAccountType value: ${defaultAccountType}`);
  }
  return ctas;
}

export enum LandingPageAction {
  CREATE_SINGLE_USE_INVITE = 'CREATE_SINGLE_USE_INVITE',
  CREATE_NORMAL = 'CREATE_NORMAL',
  CREATE_SINGLE_USE = 'CREATE_SINGLE_USE',
  ATTACH_TO_PLAN = 'ATTACH_TO_PLAN',
  LOGIN = 'LOGIN',
  SIGNUP_SINGLE_USE = 'SIGNUP_SINGLE_USE',
  SIGNUP_SINGLE_USE_INVITE = 'SIGNUP_SINGLE_USE_INVITE',
  SIGNUP_NORMAL = 'SIGNUP_NORMAL',
  SIGNUP_NONE = 'SIGNUP_NONE',
  SIGNUP_NONE_INVITE = 'SIGNUP_NONE_INVITE',
  LOGIN_AND_ATTACH = 'LOGIN_AND_ATTACH',
  RESTART = 'RESTART',
  CHECK_IN = 'CHECK_IN',
  LOGIN_THEN_CHECK_IN = 'LOGIN_THEN_CHECK_IN',
  LOGOUT = 'LOGOUT',
}

export const LANDING_PAGE_ACTION_QUERY_PARAM = 'landingPageAction';
export const LANDING_PATH = '/landing';
export const NO_CONTACT_DOMAIN = 'anon.healix.me';

export function isNoContactDomainAddress(email: string): boolean {
  return email.endsWith(NO_CONTACT_DOMAIN);
}

export const SECURE_TIME_STAMP_QUERY_PARAM = 'secureTimeStamp';
export const SECURE_HMAC_QUERY_PARAM = 'secureToken';
