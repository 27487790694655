export interface ScenarioData {
  scenarios: Scenario[];
  tagList?: Tag[];
}

export interface Scenario {
  id: string;
  title: string;
  description?: string;
  steps: Step[];
  tags: string[];
}
export interface Tag {
  id: string;
  name: string;
  description?: string;
}
export enum StepType {
  FORM_SUBMISSION = 'FORM_SUBMISSION',
  TIME_SET = 'TIME_SET',
  TEST = 'TEST',
  DEFAULT = '',
}

export enum TestType {
  MANUAL_REVIEW = 'MANUAL_REVIEW',
  LOGICAL_EXPRESSION = 'LOGICAL_EXPRESSION',
  FORM_ASSIGNMENT = 'FORM_ASSIGNMENT',
  SMS_SEND = 'SMS_SEND',
  EMAIL_SEND = 'EMAIL_SEND',
  DEFAULT = '',
}

export enum FormAssignmentTestComparisonType {
  ALL = 'ALL',
  ALL_AND_NONE_OTHERS = 'ALL_AND_NONE_OTHERS',
  NONE = 'NONE',
  DOES_NOT_INCLUDE = 'DOES_NOT_INCLUDE',
  DEFAULT = '',
}

export enum NotificationProtocolSelection {
  NONE = 'NONE',
  TRACKED = 'TRACKED',
  IMPORTANT = 'IMPORTANT',
  URGENT = 'URGENT',
  ANY = 'ANY',
}

export interface FormAssignmentTestAttributes {
  formNames: string[];
  comparisonType: FormAssignmentTestComparisonType;
  role: string;
  notificationProtocol: NotificationProtocolSelection | string; // Do we need to allow them to be strings?
}
export interface TestStepAttributes {
  testType: TestType;
  fromAssignmentTestAttributes?: FormAssignmentTestAttributes;
}
export interface Step {
  name: string;
  stepType: StepType;
  description?: string;
  formSubmissionStepAttributes?: FormSubmissionStepAttributes; // stepType === 'FORM_SUBMISSION'
  asAt?: string; // stepType === 'TIME_SET' (ISO string)
  testStepAttributes?: TestStepAttributes; // stepType === 'TEST'
  runResult?: TestStepResults;
}

export interface TestStepResults {
  success: boolean;
  errorMessage?: string;
}

export interface FormSubmissionStepAttributes {
  orgId: string;
  templateName: string;
  role: string;
  formName: string;
  formData: any;
}
