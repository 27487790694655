export const AS_USER_ID_QUERY_PARAM = 'as_user_id';
export const SIMULATOR_CHANNEL_ID_QUERY_PARAM = 'sim_channel_id';
export const SIMULATION_ID_QUERY_PARAM = 'sim_id';
export const TEMPLATE_REVISION_ID_QUERY_PARAM = 'template_rev_id';
export const SIMULATOR_CAREPLAN_ID_PARAM = 'sim_cp_id';

export function calculateSimulatorUrl({
  user,
  channelId,
  templateRevisionId,
  planId,
  simulationId,
  baseUrl,
}: {
  baseUrl: string;
  user?: { userId: string };
  channelId: string | undefined;
  templateRevisionId: string;
  planId?: string;
  simulationId: string;
}): string {
  if (!channelId) {
    return 'about:blank';
  }
  const url = `${baseUrl}?${SIMULATOR_CHANNEL_ID_QUERY_PARAM}=${channelId}&${SIMULATION_ID_QUERY_PARAM}=${simulationId}&${TEMPLATE_REVISION_ID_QUERY_PARAM}=${templateRevisionId}${
    planId ? `&${SIMULATOR_CAREPLAN_ID_PARAM}=${planId}` : ''
  }${user?.userId ? `&${AS_USER_ID_QUERY_PARAM}=${user.userId}` : ''}`;
  return url;
}
