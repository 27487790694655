import { ENTITY_ID } from './entity';
import { LocalizedString } from './localized-string';

type WorkListDisplayDataSourceType = 'work-queue-item' | 'dynamic-data-model';
type WorkListDisplayDynamicDataModelType = 'field-reference' | 'expression';

export interface WorkListDisplayDataSource {
  dynamicDataModelType: WorkListDisplayDynamicDataModelType;
  expression?: string;
  entityId?: ENTITY_ID;
  categoryId?: string;
  fieldName?: string;
}

export interface WorkQueueDisplayIdentifier {
  deviceName: string;
  orgId: string;
}

export type WorkQueueItemDisplayFieldId =
  | 'name'
  | 'ticketNumber'
  | 'priority'
  | 'serviceLocation'
  | 'status'
  | 'notes'
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'queuePosition'
  | 'createdAgo'
  | 'planId'
  | 'phoneNumber'
  | 'email'
  | 'age';

export type WorkListOrderFieldId =
  | 'name'
  | 'ticketNumber'
  | 'priority'
  | 'serviceLocation'
  | 'status'
  | 'createdAt'
  | 'updatedAt'
  | 'queuePosition'
  | 'none';
export interface WorkListDisplayColumn {
  label: LocalizedString;
  dataSourceType: WorkListDisplayDataSourceType;
  workQueueItemFieldName?: WorkQueueItemDisplayFieldId;
  dataSource?: WorkListDisplayDataSource;
  colors?: WorkListDisplayColumnColorMap[];
  defaultColor?: ColorPair;
}

export interface ColorPair {
  textColor?: string;
  backgroundColor?: string;
}

export interface StringColorMap extends ColorPair {
  value: string;
}

export interface RangeColorMap extends ColorPair {
  greaterThanOrEqual: number;
  lessThan: number;
}

export interface WorkListOrder {
  field: WorkListOrderFieldId;
  order: 'asc' | 'desc';
}

// export interface WorkListDisplayColumnColor extends StringColorMap, RangeColorMap {}
export type WorkListDisplayColumnColorMap = StringColorMap | RangeColorMap;
export interface WorkListDisplay {
  columns: WorkListDisplayColumn[];
  defaultOrder: WorkListOrder[];
}

export const EMPTY_WORK_LIST_DISPLAY: WorkListDisplay = {
  columns: [],
  defaultOrder: [],
};

export function checkWorkListDisplay(workListDisplay: WorkListDisplay | undefined | null): WorkListDisplay {
  if (!workListDisplay) {
    workListDisplay = EMPTY_WORK_LIST_DISPLAY;
  }
  if (!workListDisplay.columns) {
    workListDisplay.columns = [
      {
        dataSourceType: 'work-queue-item',
        label: [{ l: 'en', s: 'Status' }],
      },
    ];
  }
  if (!workListDisplay.defaultOrder?.length) {
    workListDisplay.defaultOrder = [{ field: 'createdAt', order: 'asc' }];
  }
  return workListDisplay;
}
