export enum NotificationProtocol {
  NONE = 'NONE',
  TRACKED = 'TRACKED',
  IMPORTANT = 'IMPORTANT',
  URGENT = 'URGENT',
}

export interface NotificationParams {
  inApp: boolean;
  hoursToInApp?: number;
  external: boolean;
  hoursToExternal?: number;
}
